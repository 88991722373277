import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, CanActivate, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DistrictAdminGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private svAuth: AuthenticationService) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.districtAdminCheck();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.districtAdminCheck();
  }

  private districtAdminCheck() {
    return this.svAuth.state$.pipe(
      map(state => {
        if (!state.auth) {
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (!state.user) {
          return false;
        }
        return (state.user.isGlobalAdmin || state.user.isDistrictAdmin);
      })
    ).toPromise();
  }

}
