import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { LoggerService } from '@orion-modules/core/services/logger.service';
import { AuthenticationService } from '@orion-modules/core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PageInformationService {
  public title$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private svRouter: Router,
    private activatedRoute: ActivatedRoute,
    private svLogger: LoggerService,
    private svAuthentication: AuthenticationService
  ) {
    this.svRouter.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      tap(route => {
        const url = this.svRouter.url;
        this.svLogger.logPageView('Page Navigation Event', url);
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.title$.next(event['title']);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
      // this.links$.next(event['links']);
    });
  }
}
