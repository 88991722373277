import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@orion-environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  public userImageEndpoint = ``;

  constructor(private http: HttpClient) { }

  uploadApplicationImage(id: number, img: File) {
    return this.http.post(`${ environment.apiUrl }/image/application/${ id }`,
      this.getData(img),
      { headers: this.getHeaders() });
  }

  uploadUserImage(id: number, img: File) {
    return this.http.post(`${ environment.apiUrl }/image/user/${ id }`,
      this.getData(img),
      { headers: this.getHeaders() });
  }

  getUserImageUrl(id: number) {
    return `${ environment.apiUrl }/image/user/${ id }`;
  }

  private getHeaders() {
    const headers = new HttpHeaders();
    /** No need to include Content-Type in Angular 4 */
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    return headers;
  }

  private getData(img) {
    const data: FormData = new FormData();
    data.append('image', img, img.name);
    return data;
  }
}
