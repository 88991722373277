import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { IFileUploadEvent } from '@orion-models/file-upload-event.interface';

@Component({
  selector: 'app-orion-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @ViewChild('fileUpload', { static: false }) public fileUpload;
  @Input() public FileUploadAcceptedFileTypes: string;
  @Input() public FileUploadMultiple = false;
  @Output() public FileUploadSelected = new EventEmitter<IFileUploadEvent>();
  @Output() public FileUploadCleared = new EventEmitter<boolean>();


  public icons = {
    upload: faFileUpload,
  };
  public uploadEvent;

  constructor() {}

  onFileSelected(event) {
    console.log(event);
    const files: File[] = event.target.files;

    this.uploadEvent = {
      files,
      names: [],
      msgs: [],
    };

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      try {
        if (!file) {
          throw new Error(`Error loading file. Please try again.`);
        }

        this.uploadEvent.names.push(file.name);
      } catch (e) {
        this.uploadEvent.msgs.push(e.message);
      }
    }

    this.FileUploadSelected.emit(this.uploadEvent);
  }

  clear() {
    // Do check because some pages will remove it from dom.
    if (this.fileUpload) {
      this.fileUpload.nativeElement.value = "";
    }
    this.uploadEvent = null;
    this.FileUploadCleared.emit(true);
  }
}
