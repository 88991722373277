import { animate, query, state, style, transition, trigger } from '@angular/animations';

export const fade = trigger('fade', [
  transition('* <=> *', [
    /* 1 */
    query(':leave', style({ opacity: '0' }), { optional: true }),
    query(':enter', [
      style({ opacity: '0' }),
      animate('0.3s ease-in-out', style({ opacity: '1' }))
    ], { optional: true })
  ])
]);
