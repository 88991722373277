<main class="tdoe-content container orion-error-page tdoe-text-center">
  <fa-icon [icon]="icons.locked" size="5x"></fa-icon>

  <h1 class="orion-header">
    Please Login
  </h1>
  <p class="orion-subheader">
    You will need to log into your Tennessee Department of Education Single Sign on (SSO) account in order to
    access the Orion website. Click <a id="linkLogin" href="javascript:void(0);" (click)="login()">Here</a> to login to your SSO
    account. If the problem persists please contact <a [routerLink]="['/help']">support</a>.
  </p>
  <p class="orion-subheader">
    Thank you for using Orion!
  </p>
</main>