export const environment = {
  envName: 'dev',
  debug: true,
  production: true,
  apiUrl: 'https://dev-orion-api.tneducation.net/api',
  legacyPortal: 'https://k-12test.tn.gov/Authorize/login.aspx',
  insightsKey: 'b8a80d5a-ce85-407e-b358-795069f22479',
  eisSupportEmail: 'dt.support@tn.gov',
  eisSupportPhone: '800-495-4154',
  validation: {
    minDate: new Date(1800, 1, 1),
    maxDate: new Date()
  }
};
