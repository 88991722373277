import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@orion-environments/environment';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { IFeedbackMessage, FeedbackMessage } from 'app/models/user.feedback';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  submitFeedback(message: string) {
    const data = new FeedbackMessage(message);
    return this.http.post(`${ environment.apiUrl }/feedback`, data);
  }

}

