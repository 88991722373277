import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// https://github.com/angular/flex-layout
// import { FlexLayoutModule } from '@angular/flex-layout';
// https://material.angular.io/
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
// https://github.com/VadimDez/ngx-order-pipe
import { OrderModule } from 'ngx-order-pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthModule } from 'angular-auth-oidc-client';
import { FlexLayoutModule } from '@angular/flex-layout';

// Custom Shared Components
import { ApplicationLoadingComponent } from './features/application-loading/application-loading.component';
import { ComponentLoaderComponent } from './features/component-loader/component-loader.component';

// Custom Shared Providers / Guards / Interceptors
import { PageLoaderComponent } from './features/page-loader/page-loader.component';
import { AuthInterceptor } from '@orion-modules/shared/interceptors/http-response.interceptor';
import { FooterComponent } from './features/footer/footer.component';
import {
  SubmitFeedbackComponent,
  SubmitFeedbackDialogComponent
} from './features/submit-feedback/submit-feedback.component';
import { GenericDialogComponent } from './features/generic-dialog/generic-dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';

const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  FlexLayoutModule,
  OrderModule,
  FontAwesomeModule,

  AuthModule,

  MatButtonModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatCardModule,
  MatIconModule,
  MatMenuModule,
  MatTabsModule,
  MatToolbarModule,
  MatDialogModule,
  MatTooltipModule,
  MatInputModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatListModule
];

const COMPONENTS = [
  ApplicationLoadingComponent,
  ComponentLoaderComponent,
  PageLoaderComponent,
  FooterComponent,
  SubmitFeedbackComponent,
  SubmitFeedbackDialogComponent,
  GenericDialogComponent,
  FileUploadComponent
];

const PROVIDERS = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

@NgModule({
  imports: [MODULES],
  declarations: [COMPONENTS],
  entryComponents: [SubmitFeedbackDialogComponent, GenericDialogComponent],
  exports: [MODULES, COMPONENTS]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: PROVIDERS,
    };
  }
}
