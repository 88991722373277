<footer class="orion-footer">
  <div class="orion-footer-content">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
      <div fxFlex="100%" fxFlex.gt-sm="50%">
        <h3 class="orion-footer-header">Tennessee Department of Education</h3>
        <p>
          Districts and schools in Tennessee will exemplify excellence and equity such that all students are equipped with the knowledge and
          skills to successfully embark upon their chosen path in life.
        </p>
      </div>
      <div fxFlex="100%" fxFlex.gt-sm="25%">
        <h4 class="orion-footer-header">Tennessee Services</h4>
        <ul class="orion-footer-links">
          <li>
            <a href="https://www.tn.gov/state-services.html" target="_blank">TN Services</a>
          </li>
          <li>
            <a href="http://www.tn.gov/directory" target="_blank">TN Directory</a>
          </li>
          <li>
            <a href="https://www.tn.gov/about-tn/state-symbols.html" target="_blank">TN About</a>
          </li>
          <li>
            <a href="https://www.tn.gov/about-tn/state-symbols.html" target="_blank">TN Symbols</a>
          </li>
          <li>
            <a href="https://www.tn.gov/help-tn-main.html" target="_blank">TN Support</a>
          </li>
          <li>
            <a href="https://www.tn.gov/web-policies/privacy-statement.html" target="_blank">TN Policies</a>
          </li>
        </ul>
      </div>
      <div fxFlex="100%" fxFlex.gt-sm="25%">
        <h4 class="orion-footer-header">Website Services</h4>
        <ul class="orion-footer-links">
          <li>
            <a href="https://www.tn.gov/transparenttn.html" target="_blank">Transparent TN</a>
          </li>
          <li>
            <a href="https://www.tn.gov/humanrights/file-a-discrimination-complaint/title-vi1/title-vi-disclaimer.html" target="_blank"
              >Title VI</a
            >
          </li>
          <li>
            <a href="https://www.tn.gov/web-policies/accessibility.html" target="_blank">Accessibility</a>
          </li>
          <li>
            <a href="https://www.tn.gov/survey.html" target="_blank">Survey</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
