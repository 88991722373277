import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, CanActivate, UrlSegment, ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { tap, map, catchError, filter, first, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SiteAdminGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private svAuth: AuthenticationService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.siteAdminCheck();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.siteAdminCheck();
  }

  private siteAdminCheck() {
    return this.svAuth.state$.pipe(
      map(state => {
        if (!state.auth) {
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (!state.user) {
          return false;
        }
        return (state.user.isGlobalAdmin || state.user.isSiteAdmin);
      })
    ).toPromise();
  }

}
