import { Injectable } from '@angular/core';

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { environment } from '@orion-environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.insightsKey,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (environment.debug) {
      console.log(name, properties);
    }
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (environment.debug) {
      console.log(name, properties);
    }
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: SeverityLevel): Error {
    if (environment.debug) {
      console.log(exception, severityLevel);
    }
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });

    return exception;
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (environment.debug) {
      console.log(message, properties);
    }
    this.appInsights.trackTrace({ message: message}, properties);
  }
}


// try {
//   // this.http.post(`${ environment.apiUrl }/log/event`, {
//   //   message: {
//   //     details: JSON.stringify(details),
//   //     properties: JSON.stringify(properties)
//   //   }
//   // }).pipe(take(1)).subscribe();
// } catch (e) {
//   this.logTEDSError(e, details);
// }
