<input #fileUpload type="file" class="file-input" (change)="onFileSelected($event)" [multiple]="FileUploadMultiple" [accept]="FileUploadAcceptedFileTypes" />

<div class="file-upload">
  <p *ngIf="!uploadEvent || uploadEvent?.names?.length === 0">No file selected.</p>
  <p *ngFor="let fileName of uploadEvent?.names">
    {{ fileName }}
  </p>

  <button type="button" color="primary" mat-flat-button (click)="fileUpload.click()">
    <fa-icon [icon]="icons.upload"></fa-icon> {{ 'Select File' + (FileUploadMultiple ? '(s)' : '') }}
  </button>
</div>
<p *ngFor="let errorMessage of uploadEvent?.msgs" class="tdoe-color-red">
  {{ errorMessage }}
</p>
