import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  console.log('Running Angular in production mode. This should be for all environments that are deployed to k8s.');
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
