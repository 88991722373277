<div class="tdoe-text-center">
  <mat-spinner [diameter]="80" *ngIf="error === false; else errorTemplate"></mat-spinner>
  <ng-template #errorTemplate>
    <span class="fa-layers fa-fw fa-4x">
      <i class="fas fa-circle" style="color:Tomato"></i>
      <i class="fa-inverse fas fa-times" data-fa-transform="shrink-6"></i>
    </span>
    <h4>ERROR</h4>
  </ng-template>
  <p>{{ message }}</p>
</div>