import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { fade } from '@orion-animations/fade.animation';

@Component({
  selector: 'app-orion-component-loader',
  templateUrl: './component-loader.component.html',
  styleUrls: ['./component-loader.component.scss'],
  animations: [fade]
})
export class ComponentLoaderComponent implements OnInit {
  @HostBinding('class.orion-component-loader') c = true;
  @HostBinding('@fade')
  public fade = true;

  @Input('message')
  public message = 'Loading Applications...';

  @Input('error')
  public error = false;

  constructor() { }

  ngOnInit() {
  }
}
