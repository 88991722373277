import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-orion-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent {
  status = 'warning';
  message = 'Are you sure?';
  tText = 'Yes';
  fText = 'No';

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      status: string;
      message: string;
      tText: string;
      fText: string;
    }
  ) {
    this.message = this.data.message ? this.data.message : this.message;
    this.tText = this.data.tText ? this.data.tText : this.tText;
    this.fText = this.data.fText ? this.data.fText : this.fText;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  resolve(status: boolean): void {
    this.dialogRef.close(status);
  }
}
