export class ComponentState {
  loading: boolean;
  error: boolean;
  message: string;
  changed?: boolean;

  _defaultTimeout: number;

  constructor(loading: boolean, message?: string) {
    this.loading = loading;
    this.message = message || 'Loading... Please wait.';
    this.error = false;
    this.changed = false;

    this._defaultTimeout = 10000;
  }

  setLoading(message?: string) {
    this.loading = true;
    this.error = false;

    if (message) {
      this.message = message;
    }
  }

  stopLoading(message?: string, timeout?: number) {
    setTimeout(() => {
      this.loading = false;
      this.message = message || 'Successfully loaded.';
    }, timeout || 0);
  }

  setError(message?: string, timeout?: number) {
    this.loading = false;
    this.error = true;
    this.message = message || 'Unspecified Error.';
    if (timeout) {
      this.message += ` \nPlease try again in ${ timeout / 1000 } sec.`;

      setTimeout(() => {
        this.loading = false;
        this.error = false;
        this.message = '';
      }, timeout);
    }
  }

  clearError() {
    this.error = false;
    this.loading = false;
    this.message = '';
  }
}
