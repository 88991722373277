import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { first, filter } from 'rxjs/operators';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';

@Component({
  selector: 'app-orion-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private router: Router,
    private oidc: OidcSecurityService,
    private svAuthentication: AuthenticationService) { }

  ngOnInit() {
    if (this.oidc.moduleSetup) {
      this.doCallbackLogicIfRequired();
    } else {
      this.oidc.onModuleSetup
      .pipe(
        first()
      ).toPromise().then(() => {
        this.doCallbackLogicIfRequired();
      });
    }
  }

  private async doCallbackLogicIfRequired() {
    // Will do a callback, if the url has a code and state parameter.
    // this.oidc.authorizedCallbackWithCode(window.location.toString());

    if (window.location.hash) {
      this.oidc.authorizedImplicitFlowCallback(window.location.hash.substr(1));
    }

    try {
      // const authorized = await this.oidc.getIsAuthorized().pipe(filter(auth => auth), first()).toPromise();
      const authorized = await this.oidc.getIsAuthorized().pipe(filter(auth => auth), first()).toPromise();      
      const userData = await this.oidc.getUserData().pipe(first()).toPromise();
      // const userInfo = await this.oidc.getUserinfo().pipe(first()).toPromise();
      // const user = await this.svAuthentication.getCurrentUser();

      if (authorized) {
        await this.router.navigate(['/']);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
