import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-orion-application-error',
  templateUrl: './application-error.component.html'
})
export class ApplicationErrorComponent implements OnInit {
  public icons = {
    error: faExclamationTriangle
  };
  public message: string;

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.message = this.activeRoute.snapshot.queryParams['message'];
  }
}
