import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { TDOESidebarLayoutModule } from '@tdoe/tdoe-component-library';
import { TDOEHeaderBarModule } from '@tdoe/tdoe-component-library';

import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from 'app/app.routing.module';
import { SharedModule } from '@orion-modules/shared/shared.module';
import { UnauthorizedComponent } from './errors/unauthorized/unauthorized.component';
import { ApplicationErrorComponent } from './errors/application-error/application-error.component';
import { ProfileComponent } from './profile/profile.component';
import { TDOEModule } from '@tdoe/tdoe-component-library';
import { CallbackComponent } from './callback/callback.component';
import { CoreModule } from '@orion-modules/core/core.module';
import { LoggedOutComponent } from './logged-out/logged-out.component';

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    ApplicationErrorComponent,
    ProfileComponent,
    LoggedOutComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    TDOEModule.forRoot(),
    TDOESidebarLayoutModule,
    TDOEHeaderBarModule,

    CoreModule,
    SharedModule.forRoot(),

    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
