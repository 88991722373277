import { Component } from '@angular/core';

import { faSmile, faLock } from '@fortawesome/free-solid-svg-icons';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';

@Component({
  selector: 'app-orion-unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent {
  public icons = {
    locked: faLock,
    smiley: faSmile
  };

  constructor(private svAuth: AuthenticationService) { }

  login() {
    this.svAuth.login();
  }
}
