<main class="tdoe-content container orion-error-page tdoe-text-center">
  <fa-icon [icon]="icons.error" size="5x" style="color: red;"></fa-icon>

  <h1 class="orion-header">
    Application Error
  </h1>
  <p class="orion-subheader">
    An error has occured within the application. Please refresh the page and try again. If the problem persists please
    <a [routerLink]="['/help']">contact support</a>
  </p>
  <br />
  <p class="orion-subheader" *ngIf="message">
    {{ message }}
  </p>
  <p><a [routerLink]="['/']">Back Home</a></p>
</main>