import {
  Router,
  Event as RouterEvent,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';
import { Component, OnInit, NgZone, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, merge, fromEvent, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  faExternalLinkSquareAlt,
  faUserCog,
  faCogs,
  faQuestionCircle,
  faUser,
  faSmile,
  faFileInvoice,
  faCaretDown,
  faSignOutAlt,
  faSignInAlt,
  faRobot,
  faTimes,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import { routerTransition } from '@orion-animations/router-transition.animation';
import { AuthenticationService } from '@orion-modules/core/services/authentication.service';
import { fade } from '@orion-animations/fade.animation';
import { PageInformationService } from '@orion-services/page-information.service';
import { ComponentState } from '@orion-models/component-state.model';
import { IUser } from '@orion-models/user.interface';

// Dynatrace
declare var dT_; // Declaration only needed in TypeScript. Remove this line if you are using JavaScript

@Component({
  selector: 'app-orion-root',
  templateUrl: './app.component.html',
  animations: [routerTransition, fade],
})
export class AppComponent implements OnInit {
  // Instead of holding a boolean value for whether the spinner
  // should show or not, we store a reference to the spinner element,
  // see template snippet below this script
  @ViewChild('spinnerElement', { static: true }) spinnerElement: ElementRef;
  @ViewChild('contentElement', { static: false }) contentElement: ElementRef;

  public state: ComponentState;
  public ready$: Observable<boolean>;
  public auth$: Observable<boolean>;
  public user$: Observable<IUser>;
  public online$: Observable<boolean>;
  public title$: BehaviorSubject<string>;
  public icons = {
    dashboard: faExternalLinkSquareAlt,
    manageApplication: faCogs,
    manageUser: faUserCog,
    manageProvisioner: faRobot,
    reportUser: faFileInvoice,
    help: faQuestionCircle,
    feedback: faSmile,
    user: faUser,
    caretDown: faCaretDown,
    signOut: faSignOutAlt,
    login: faSignInAlt,
    close: faTimes,
    error: faExclamationTriangle,
  };

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private svPageDetails: PageInformationService,
    private http: HttpClient,
    public svAuth: AuthenticationService
  ) {
    // Dynatrace
    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(http, HttpHeaders);
    }

    this.state = new ComponentState(true, 'Initializing application... Please wait.');

    this.auth$ = this.svAuth.auth$;
    this.user$ = this.svAuth.user$.asObservable();
    this.ready$ = this.svAuth.initialized$.asObservable();
    this.title$ = this.svPageDetails.title$;
    this.router.onSameUrlNavigation = 'reload';
    this.router.events.subscribe((event: RouterEvent) => {
      this._navigationInterceptor(event);
    });

    this.online$ = merge(
      // use .map() to transform the returned Event type into a true/false value
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      // start the stream with the current online status
      Observable.create((sub) => {
        sub.next(navigator.onLine);
      })
    );
  }

  ngOnInit() {}

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }

  getDepth(outlet) {
    return outlet.activatedRouteData['depth'];
  }

  logout() {
    this.svAuth.logout();
  }

  login() {
    try {
      this.svAuth.login();
    } catch (e) {
      this.svAuth.logout();
    }
  }

  handleHideError(errors: string[], index: number) {
    errors.splice(index, 1);
  }

  // Shows and hides the loading spinner during RouterEvent changes
  private _navigationInterceptor(event: RouterEvent): void {
    if (event instanceof RouteConfigLoadStart) {
      this.ngZone.runOutsideAngular(() => {
        if (this.contentElement) {
          setTimeout(() => {
            this.renderer.addClass(this.contentElement.nativeElement, 'no-scroll');
            this.renderer.addClass(this.spinnerElement.nativeElement, 'active');
          }, 0);
        }
      });
    }

    // Set loading state to false in both of the below events to
    // hide the spinner in case a request fails
    if (event instanceof RouteConfigLoadEnd) {
      this._hideSpinner();
    }
    if (event instanceof NavigationEnd) {
      this._hideSpinner();
      window.scrollTo(0, 0);
    }
    if (event instanceof NavigationCancel) {
      this._hideSpinner();
    }
    if (event instanceof NavigationError) {
      this._hideSpinner();
    }
  }

  private _hideSpinner(): void {
    // We wanna run this function outside of Angular's zone to
    // bypass change detection,
    this.ngZone.runOutsideAngular(() => {
      if (this.contentElement) {
        setTimeout(() => {
          this.renderer.removeClass(this.contentElement.nativeElement, 'no-scroll');
          this.renderer.removeClass(this.spinnerElement.nativeElement, 'active');
        }, 1000);
      } else {
        this._fallbackDismissSpinner();
      }
    });
  }

  private _fallbackDismissSpinner() {
    if (this.contentElement) {
      this._hideSpinner();
      return;
    }
    setTimeout(() => {
      this._fallbackDismissSpinner();
    }, 1000);
  }
}
