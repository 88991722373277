<!-- Offline Indicator -->
<div fxLayout="column" fxLayoutAlign="center center" class="orion-offline-notify"
  *ngIf="(online$ | async) === false" [@fade]="">
  <div class="tdoe-text-center">

    <div class="fa-layers fa-fw fa-4x">
      <i class="fas fa-plug" data-fa-transform="shrink-6"></i>
      <i class="fas fa-ban" style="color:red;"></i>
    </div>
    <h1>You are offline</h1>
    <h4>You will be unable to work on Orion until your internet has restored.</h4>
  </div>
</div>
<!-- Module loading Indicator -->
<div #spinnerElement
  class="orion-loading-overlay active" fxLayout="column" fxLayoutAlign="center center">
  <div class="tdoe-content tdoe-text-center">
    <img src="/assets/img/orion-logo.png" alt="Orion" class="orion-brand" /> <br />
    Loading Page...
  </div>
</div>
<!-- App Initialization Indicator -->
<ng-template #initApp>
  <app-orion-application-loading></app-orion-application-loading>
</ng-template>

<!-- Begin Content -->

<div #contentElement *ngIf="(ready$ | async) as ready; else initApp">
  <!-- <div #contentElement *ngIf="ready$ | async; else initApp"> -->

  <tdoe-sidebar-layout [small]="true" imageUrl="/assets/img/orion-logo.png"
    *ngIf="(user$ | async) as user; else noUser">
    <!-- Sidebar -->
    <div class="tdoe-sidebar-content">
      <mat-list>
        <a routerLink="/launcher" mat-list-item class="tdoe-sidebar-link" routerLinkActive="active">
          <fa-icon [icon]="icons.dashboard" [fixedWidth]="true"></fa-icon>
          <span class="tdoe-sidebar-link-text">Application Launcher</span>
        </a>
        <a routerLink="/application" *ngIf="user.isGlobalAdmin || user.isSiteAdmin" mat-list-item
          class="tdoe-sidebar-link" routerLinkActive="active">
          <fa-icon [icon]="icons.manageApplication" [fixedWidth]="true"></fa-icon>
          <span class="tdoe-sidebar-link-text">Manage Applications</span>
        </a>
        <a routerLink="/user" *ngIf="user.isGlobalAdmin || user.isSiteAdmin || user.isUserAdmin" mat-list-item
          class="tdoe-sidebar-link" routerLinkActive="active">
          <fa-icon [icon]="icons.manageUser" [fixedWidth]="true"></fa-icon>
          <span class="tdoe-sidebar-link-text">Manage Users</span>
        </a>
        <a routerLink="/provisioner" *ngIf="user.isGlobalAdmin" mat-list-item class="tdoe-sidebar-link"
          routerLinkActive="active">
          <fa-icon [icon]="icons.manageProvisioner" [fixedWidth]="true"></fa-icon>
          <span class="tdoe-sidebar-link-text">Manage Provisioner</span>
        </a>
        <a routerLink="/report/user" *ngIf="user.isGlobalAdmin || user.isDistrictAdmin" mat-list-item
          class="tdoe-sidebar-link" routerLinkActive="active">
          <fa-icon [icon]="icons.reportUser" [fixedWidth]="true"></fa-icon>
          <span class="tdoe-sidebar-link-text">SSO User Report</span>
        </a>
        <a routerLink="/help" mat-list-item class="tdoe-sidebar-link" routerLinkActive="active">
          <fa-icon [icon]="icons.help" [fixedWidth]="true"></fa-icon>
          <span class="tdoe-sidebar-link-text">Help</span>
        </a>
      </mat-list>
    </div>

    <div class="tdoe-page-content">
      <!-- Warning Messages -->
      <div class="orion-browser-warning tdoe-text-center">
        <p>
          Please upgrade your browser to the latest version of Edge, Chrome, or Firefox. Your
          current browser is not supported!
        </p>
      </div>
      <noscript>
        <h1 class="tdoe-text-center">
          You will need to enable javascript in your browser settings and try again.
        </h1>
      </noscript>
      <!-- /Warning Messages -->

      <div class="tdoe-page-content">
        <!-- Account Errors banner -->
        <!-- TODO: Uncomment this on next release -->
        <!-- <div *ngIf="(user$ | async)?.accountErrors as errors">
          <mat-toolbar color="warn" *ngIf="errors.length > 0">
            <mat-toolbar-row *ngFor="let error of errors; let i = index">
              <fa-icon [icon]="icons.error" [fixedWidth]="true"></fa-icon>&nbsp;{{ error }}
              <div class="tdoe-spacer"></div>
              <button (click)="handleHideError(errors, i)" type="button" mat-button>
                <fa-icon [icon]="icons.close" [fixedWidth]="true"></fa-icon>
              </button>
            </mat-toolbar-row>
          </mat-toolbar>
        </div> -->
        <!-- Header -->
        <tdoe-header-bar>
          <span *ngIf="(title$ | async) as title" [@fade]="title">{{ title }}</span>
          <div class="tdoe-spacer"></div>
          <button type="button" mat-button [routerLink]="[{ outlets: { modal: 'submit-feedback' } }]"
            queryParamsHandling="merge">
            <fa-icon [icon]="icons.feedback" [fixedWidth]="true" size="lg"></fa-icon>
          </button>
          <button type="button" mat-button [matMenuTriggerFor]="profileMenu" *ngIf="(user$ | async) as user">
            <!-- <img mat-fab-image [src]="" *ngIf="user.hasImage"
                class="orion-profile-image-button"> -->
            <span id="tdoe-username">{{ user.displayName }}</span>
            <fa-icon [icon]="icons.caretDown" [fixedWidth]="true"></fa-icon>
          </button>
          <mat-menu #profileMenu="matMenu">
            <a mat-menu-item [routerLink]="['/profile']">
              <fa-icon [icon]="icons.user" [fixedWidth]="true"></fa-icon>
              &nbsp;
              <span>Profile</span>
            </a>
            <!-- <button mat-menu-item (click)="resetCache()">
                <mat-icon fontSet="fa" fontIcon="fa-refresh"></mat-icon>
                <span>Reset Cache</span>
              </button> -->
            <button type="button" mat-menu-item (click)="logout()">
              <fa-icon [icon]="icons.signOut" [fixedWidth]="true"></fa-icon>
              &nbsp;
              <span>Sign Out</span>
            </button>
          </mat-menu>
        </tdoe-header-bar>

        <article class="container-fluid tdoe-position-relative" [@routerTransition]="getDepth(myOutlet)">
          <!-- Application Content -->
          <router-outlet #myOutlet="outlet"></router-outlet>
          <router-outlet name="modal" *ngIf="(user$ | async)"></router-outlet>
        </article>
        <!-- Application Footer -->
        <!-- <app-orion-footer *ngIf="user"></app-orion-footer> -->
      </div>
    </div>
  </tdoe-sidebar-layout>


  <ng-template #noUser>
    <tdoe-header-bar>
      <img src="/assets/img/orion-logo.png" />
      <div class="tdoe-spacer"></div>
      <button id="btnLogin" type="button" mat-raised-button *ngIf="(auth$ | async) === false" (click)="login()">
        <fa-icon [icon]="icons.login" [fixedWidth]="true"></fa-icon>&nbsp;
        <span>Login</span>
      </button>
    </tdoe-header-bar>
    <router-outlet></router-outlet>
  </ng-template>
</div>