import { Injectable } from '@angular/core';
import { Router, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, UrlTree, RouterStateSnapshot, CanActivate } from '@angular/router';

import { map } from 'rxjs/operators';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private svAuth: AuthenticationService) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.authCheck();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authCheck();
  }

  private authCheck() {
    return this.svAuth.state$.pipe(
      map(state => {
        if (!state.auth) {
          this.router.navigate(['/unauthorized']);
          return false;
        }
        return true;
      })
    ).toPromise();
  }
}
