import { Component, HostBinding, Inject, OnDestroy, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { catchError, take } from 'rxjs/operators';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { FeedbackService } from '@orion-services/feedback.service';
import { AuthenticationService } from '@orion-modules/core/services/authentication.service';
import { LoggerService } from '@orion-modules/core/services/logger.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-orion-submit-feedback',
  template: ''
})
export class SubmitFeedbackComponent implements OnDestroy {
  private _dialogRef;
  private _sub;

  constructor(public dialog: MatDialog, private router: Router) {
    // Open the modal on load of this component.
    this._dialogRef = this.dialog.open(SubmitFeedbackDialogComponent, {
      panelClass: 'orion-dialog',
      minWidth: '400px',
      minHeight: '200px'
    });

    // Setup a subscription
    this._sub = this._dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([{ outlets: { modal: null } }], { queryParamsHandling: 'merge' });
    });
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }
}

@Component({
  selector: 'app-orion-submit-feedback-dialog',
  templateUrl: './submit-feedback.component-dialog.html',
  styleUrls: ['./submit-feedback.component-dialog.scss']
})
export class SubmitFeedbackDialogComponent implements OnInit {
  feedbackForm: FormGroup;
  isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<SubmitFeedbackDialogComponent>,
    private svFeedback: FeedbackService,
    private svAuthentication: AuthenticationService,
    private svLogger: LoggerService,
    private router: Router,
    private zone: NgZone,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = false;

    this.feedbackForm = this._formBuilder.group({
      message: ['', Validators.compose([Validators.required, Validators.maxLength(500)])]
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendFeedback(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    this.isLoading = true;
    this.svFeedback
      .submitFeedback(form.value)
      .pipe(
        take(1),
        catchError(error => {
          const user = this.svAuthentication.user$.getValue();

          this.isLoading = false;
          return of(this.svLogger.logException(error, SeverityLevel.Error));
          // return this.svLogger.logError(
          //   error,
          //   {
          //     name: 'Error Submitting Feedback.',
          //     message: `An error has occured submitting the feedback: ${JSON.stringify(
          //       form.value
          //     )}`,
          //     userId: user ? user.id.toString() : '-1'
          //   },
          //   'submit-feedback.component.ts'
          // );
        })
      )
      .toPromise()
      .then(data => {
        this.isLoading = false;
        const user = this.svAuthentication.user$.getValue();

        this.svLogger.logEvent('feedback-sumbitted', {
          message: form.value,
          submittedAt: new Date(),
          userId: user.id.toString(),
          origin: 'submit-feedback.component.ts'
        });

        this.dialogRef.close();
        this.router.navigate([{ outlets: { modal: null } }]);
      });
  }
}
