import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { fade } from '@orion-animations/fade.animation';

@Component({
  selector: 'app-orion-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  animations: [fade]
})
export class PageLoaderComponent implements OnInit {
  @HostBinding('@fade')
  public fade = true;
  @Input('state')
  public state: String = '';

  private pageLoader = true;

  constructor() { }

  ngOnInit() {
  }
}
