import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SubmitFeedbackComponent } from '@orion-modules/shared/features/submit-feedback/submit-feedback.component';
import { UnauthorizedComponent } from 'app/errors/unauthorized/unauthorized.component';
import { ApplicationErrorComponent } from 'app/errors/application-error/application-error.component';
import { ProfileComponent } from 'app/profile/profile.component';
import { SiteAdminGuard } from '@orion-modules/shared/guards/site-admin.guard';
import { AdminGuard } from '@orion-modules/shared/guards/admin.guard';
import { DistrictAdminGuard } from '@orion-modules/shared/guards/district-admin.guard';
import { AuthorizationGuard } from '@orion-modules/shared/guards/auth.guard';
import { CallbackComponent } from './callback/callback.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';

const appRouting: Routes = [
  { path: '', redirectTo: 'launcher', pathMatch: 'full' },
  {
    path: 'launcher',
    data: { title: 'Application Launcher', state: 'application-launcher', depth: 1 },
    canLoad: [AuthorizationGuard],
    canActivate: [AuthorizationGuard],
    // loadChildren: './features/application-launcher/application-launcher.module#ApplicationLauncherModule'
    loadChildren: () => import('./features/application-launcher/application-launcher.module').then(m => m.ApplicationLauncherModule)
  },
  {
    path: 'help',
    data: { title: 'Help', state: 'help', depth: 1 },
    // loadChildren: './features/help/help.module#HelpModule'
    loadChildren: () => import('./features/help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'unauthorized',
    data: { title: 'Access Denied', state: 'error-unauthorized', icon: 'fa-stop', },
    component: UnauthorizedComponent,
  },
  {
    path: 'application-error',
    data: { title: 'Application Error', state: 'error-application', icon: 'fa-stop', },
    component: ApplicationErrorComponent,
  },
  {
    path: 'profile',
    data: { title: 'My Profile', state: 'profile', icon: 'fa-user', },
    component: ProfileComponent,
    canLoad: [AuthorizationGuard],
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'logged-out',
    data: { title: 'Logging Out', state: 'logged-out', icon: 'fa-user', },
    component: LoggedOutComponent
  },
  {
    path: 'application',
    data: { title: 'Application Editor', state: 'application', depth: 1 },
    canLoad: [SiteAdminGuard],
    canActivate: [SiteAdminGuard],
    // loadChildren: './features/application-editor/application-editor.module#ApplicationEditorModule'
    loadChildren: () => import('./features/application-editor/application-editor.module').then(m => m.ApplicationEditorModule)
  },
  {
    path: 'user',
    data: { title: 'User Editor', state: 'user', depth: 1 },
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
    // loadChildren: './features/user-editor/user-editor.module#UserEditorModule'
    loadChildren: () => import('./features/user-editor/user-editor.module').then(m => m.UserEditorModule)
  },
  {
    path: 'provisioner',
    data: { title: 'Provisioner Admin', state: 'provisioner', depth: 1 },
    canLoad: [AdminGuard],
    canActivate: [AdminGuard],
    // loadChildren: './features/provisioner/provisioner.module#ProvisionerModule'
    loadChildren: () => import('./features/provisioner/provisioner.module').then(m => m.ProvisionerModule)
  },
  {
    path: 'report/user',
    data: { title: 'User Report', state: 'user-report', depth: 1 },
    canLoad: [DistrictAdminGuard],
    canActivate: [DistrictAdminGuard],
    // loadChildren: './features/user-report/user-report.module#UserReportModule'
    loadChildren: () => import('./features/user-report/user-report.module').then(m => m.UserReportModule)

  },
  {
    path: 'submit-feedback',
    data: { title: 'Submit Feedback', state: 'submit-feedback', depth: 1 },
    component: SubmitFeedbackComponent,
    canLoad: [AuthorizationGuard],
    canActivate: [AuthorizationGuard],
    outlet: 'modal'
  },
  {
    path: 'callback',
    data: { title: 'OIDC Callback', state: 'oidc-callback', depth: 1 },
    component: CallbackComponent
  },
  { path: '**', redirectTo: 'launcher', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRouting, {
      enableTracing: false,
      useHash: false,
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
