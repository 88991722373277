import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, of, Observable, throwError } from 'rxjs';
import { tap, map, catchError, first, retry, switchMap, filter, share, delay } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { IUser } from '@orion-models/user.interface';
import { environment } from '@orion-environments/environment';
import { IEmailValidation } from '@orion-models/email-validation.interface';
import { IApplication } from '@orion-models/application.interface';
import { LoggerService } from './logger.service';
import { IOrganization } from '@orion-models/organization.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public user$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  public auth$: Observable<boolean>;
  public error$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public initialized$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public state$ = this.initialized$.pipe(
    filter((init) => init),
    first(),
    switchMap(() => Promise.all([this.oidc.getIsAuthorized().pipe(first()).toPromise(), this.user$.pipe(first()).toPromise()])),
    map((result) => {
      return { auth: result[0], user: result[1] };
    })
  );

  constructor(private http: HttpClient, private router: Router, private svLogger: LoggerService, private oidc: OidcSecurityService) {
    this.auth$ = this.oidc.getIsAuthorized();

    this.oidc
      .getIsAuthorized()
      .pipe(
        switchMap((auth) => {
          if (auth) {
            return this.getCurrentUser()
              .then((user) => {
                return { user };
              })
              .catch((e) => {
                // const errorMessage = 'Unable to get user state. Token or User api isn\'t working correctly.';

                this.svLogger.logException(e, SeverityLevel.Critical);
                // this.svLogger.logError(
                //   e,
                //   {
                //     name: 'authentication.service.ts',
                //     message: errorMessage,
                //     userId: null
                //   },
                //   new Date().toString()
                // );
                this.router.navigate(['/application-error'], {
                  queryParams: {
                    message: 'Unable to load user from server. Please wait for a moment and try again.',
                  },
                });
                return { user: null, error: true };
              });
          } else {
            // console.log('afbsdhbfajkhsbdjhas');
            // if (this.initialized$.getValue()) {
            //   this.router.navigate(['/unauthorized']);
            // }

            return of({ user: null });
          }
        })
      )
      .subscribe((result: { user: IUser; error?: boolean }) => {
        this.user$.next(result.user);
        this.error$.next(result.error);

        this.initialized$.next(true);
      });
  }

  async getCurrentUser(): Promise<IUser> {
    return await this.http
      .get<IUser>(`${environment.apiUrl}/user/current`)
      .pipe(
        retry(1),
        first(),
        map((user: IUser) => {
          // Set this as a default.
          user.hasImage = true;

          // Setup user applications
          user.applications = user.applications.map((app) => {
            app.imageUrl = `${environment.apiUrl}/image/application/${app.id}`;
            return app;
          });

          // The linked email mechanism is designed to mitigate users with multiple schools
          // For legacy applications
          if (user.linkedEmails && user.linkedEmails.length > 0) {
            // We are going to add our email to the list so we can select it accordingly.
            user.linkedEmails.push(user.email);
            user.activeEmail = user.email;
          } else {
            user.activeEmail = user.email;
          }

          return user;
        }),
        map((user: IUser) => {
          for (let i = 0; i < user.roles.length; i++) {
            if (user.roles[i].name === 'TDOE State Employee') {
              user.isState = true;
              break;
            }
          }

          return user;
        }),
        map((user: IUser) => {
          user.editableApplications = user.applications
            .filter((application) => {
              return !!application.userRoles.find((r) => r.siteAdmin || r.globalAdmin || r.name === 'Orion Site Admin');
            })
            .map((application) => application.id);

          return user;
        }),
        switchMap(async (user: IUser) => {
          try {
            user.accountErrors = [];

            if (user.localEducationAgencyIds.indexOf(-1) === -1) {
              const validEmail = await this.validateEmailDomain(user.email, user.localEducationAgencyIds).toPromise();

              if (!validEmail.isValid) {
                user.accountErrors.push(
                  'Invalid email domain for your LEA. You will need to contact support to have your account corrected.'
                );
              }

              return user;
            } else {
              user.accountErrors = ['No LEA assigned to your account. You will need to contact support to have your account corrected.'];
            }

            return user;
          } catch (e) {
            user.accountErrors = ['Error verifying account. Please refresh the page and try again.'];

            return user;
          }
        }),
        tap((user: IUser) => {
          if (environment.debug) {
            console.log(user);
          }
          
          this.user$.next(user);
          this.initialized$.next(true);
        }),
        catchError((e) => {
          this.svLogger.logException(e, SeverityLevel.Critical);
          // this.svLogger.logError(
          //   e,
          //   {
          //     name: 'authentication.service.ts',
          //     message: `Unable to get user state.
          //     Token or User api isn't working correctly.`,
          //     userId: null
          //   },
          //   new Date().toString()
          // );
          throw e;
        })
      )
      .toPromise();
  }

  async logout() {
    try {
      await this.router.navigate(['/logged-out']);
      this.oidc.logoff();
    } catch (e) {
      await this.router.navigate(['/application-error'], {
        queryParams: {
          message: 'Error logging out... Please try again.',
        },
      });
    }
  }

  async getLegacyApplications(email: string): Promise<IApplication[]> {
    const user = this.user$.getValue();

    if (user.email !== email && user.linkedEmails.indexOf(email) === -1) {
      throw {
        error: 'You do not have access to the information you are requesting.',
        code: 401,
      };
    }

    // return await of(user.applications).pipe(delay(2000)).toPromise();
    // TODO: Hook up this endpoint
    return await this.http.get<IApplication[]>(`${environment.apiUrl}/user/legacy/${email}`).toPromise();
  }

  login() {
    this.oidc.authorize();
  }

  getToken() {
    return this.oidc.getToken();
  }

  hasGroup(groups: string): Promise<boolean> {
    let cGroups: Array<string>;

    if (groups.indexOf('|') > -1) {
      cGroups = groups.split('|');
    } else {
      cGroups = [groups];
    }
    return this.user$
      .pipe(
        first(),
        map((user) => {
          for (let i = 0; i < cGroups.length; i++) {
            return !!user.roles.find((x) => x.name === cGroups[i]);
          }
        })
      )
      .toPromise();
  }

  validateEmailDomain(email: string, leas: Array<number>): Observable<IEmailValidation> {
    const params = new HttpParams().set('leas', leas.join(','));

    return this.http
      .get<IEmailValidation>(`${environment.apiUrl}/emaildomainvalidator/validate/${email}`, {
        params: params,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  validateLicenseNumber(licenseNumber: number) {
    // This validation requirement was changed 03/21/2019
    // Pushed forward via DJ and Bill due to EIS having bad data +
    // Provisioner having issues with only 9 numbers due to said bad data.
    const pattern = /[0-9]{1,10}/;
    const returnValue = { validLicenseNumber: true };

    if (!licenseNumber) {
      return null;
    } else if (pattern.test(licenseNumber.toString())) {
      return null;
    } else {
      return returnValue;
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message);
    } else if (error.error) {
      console.error(error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}
