<main class="tdoe-content container">
  <app-orion-component-loader *ngIf="state.loading || state.error" [error]="state.error" [message]="state.message">
  </app-orion-component-loader>

  <mat-card *ngIf="user$ | async as user;">
    <mat-card-content>
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
        <div fxFlex="100%" fxFlex.gt-sm="66%">
          <img class="orion-editor-avatar" [src]="getImageUrl(user.id)" />
          <p>Click <a href="https://portal.office.com/account/#personalinfo" target="_blank">Here</a> to update your
            profile
            picture.</p>

        </div>
        <div fxFlex="100%" fxFlex.gt-sm="33%" class="tdoe-text-center">
          <div class="o-editor-active" *ngIf="user.enabled; else notEnabled"
            matTooltip="User can Login">
            Enabled
          </div>
          <ng-template #notEnabled>
            <div class="o-editor-inactive" matTooltip="User cannot login">Disabled</div>
          </ng-template>
          <br />
        </div>
      </div>
      <br />
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between">
        <div fxFlex="100%">
          <h2 class="tdoe-content-title">
            <span>{{ user.displayName }}</span>
          </h2>
          <p class="tdoe-content-subtitle" *ngIf="user?.lastUpdated">
            <strong>Last Updated On:</strong>&nbsp;
            {{ user.lastUpdated | date: 'medium' }}
          </p>

          <div *ngIf="user.accountErrors.length > 0">
            <hr />
            <h3 class="tdoe-color-red">Account Issues</h3>
            <ul>
              <li class="tdoe-color-red" *ngFor="let error of user.accountErrors">{{ error }}</li>
            </ul>
          </div>

          <hr />
          <h3>Details</h3>
          <p class="tdoe-content-subtitle">
            <strong>UPN:</strong>&nbsp;
            <span>{{ user.upn || '--' }}</span>
          </p>
          <p class="tdoe-content-subtitle">
            <strong>Email:</strong>&nbsp;
            <span>{{ user.email || '--' }}</span>
          </p>
          <p class="tdoe-content-subtitle">
            <strong>Phone:</strong>&nbsp;
            <span>{{ user.phone || '--' }}</span>
          </p>
          <hr />
          <h3>Organizations</h3>
          <mat-chip-list>
            <mat-chip *ngFor="let org of user.associatedOrganizations">
              {{ org }}
            </mat-chip>
          </mat-chip-list>
          <hr />
          <h3>Roles</h3>
          <mat-chip-list>
            <mat-chip *ngFor="let group of user.roles">
              {{ group.name }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</main>