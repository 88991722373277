
<h1 mat-dialog-title class="tdoe-text-center">Please Confirm Action</h1>
<mat-dialog-content class="tdoe-text-center">
  <i class="fa fa-4x fa-exclamation-triangle" aria-label="Warning"></i>
  <p>
    {{ message }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="resolve(false)">{{ fText }}</button>
  <button mat-raised-button color="primary" (click)="resolve(true)">{{ tText }}</button>
</mat-dialog-actions>