<div class="feedback-content">
  <form class="feedback-form" [formGroup]="feedbackForm" (submit)="sendFeedback(feedbackForm)" novalidate>
    <mat-form-field class="feedback-full-width">
      <textarea matInput formControlName="message" matTextareaAutosize matAutosizeMinRows="10" matAutosizeMaxRows="15" placeholder="Please provide feedback"></textarea>
      <mat-hint align="end">{{ feedbackForm.get('message')?.value.length }} / 500</mat-hint>
    </mat-form-field>
    <div class="feedback-form-controls">
      <button type="submit" mat-button>Submit Feedback</button>
    </div>
  </form>
</div>
