import {
  trigger,
  animate,
  style,
  group,
  animateChild,
  query,
  stagger,
  transition
} from '@angular/animations';

export const routerTransition = trigger('routerTransition', [
  transition('1 => 2, 2 => 3', [
    style({ height: '!' }),
    query(':enter', style({ transform: 'translateX(100%)' })),
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
    // animate the leave page away
    group([
      query(':leave', [
        animate('0.4s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)' }))
      ]),
      // and now reveal the enter
      query(
        ':enter',
        animate('0.4s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' }))
      )
    ])
  ]),
  transition('3 => 2, 2 => 1', [
    style({ height: '!' }),
    query(':enter', style({ transform: 'translateX(-100%)' })),
    query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0 })),
    // animate the leave page away
    group([
      query(':leave', [
        animate('0.4s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)' }))
      ]),
      // and now reveal the enter
      query(
        ':enter',
        animate('0.4s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(0)' }))
      )
    ])
  ]),
  transition('1 => 1', [
    // The query function has three params.
    // First is the event, so this will apply on entering or when the element is added to the DOM.
    // Second is a list of styles or animations to apply.
    // Third we add a config object with optional set to true, this is to signal
    // angular that the animation may not apply as it may or may not be in the DOM.
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    query(
      ':leave',
      // here we apply a style and use the animate function to apply the style over 0.3 seconds
      [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
      { optional: true }
    ),
    query(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))], {
      optional: true
    })
  ])
]);
