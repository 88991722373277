import { Component, OnInit, HostBinding } from '@angular/core';

import { BehaviorSubject, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';
import { IUser } from '@orion-models/user.interface';
import { ImageService } from '@orion-services/image.service';
import { ComponentState } from '@orion-models/component-state.model';

@Component({
  selector: 'app-orion-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public user$: BehaviorSubject<IUser>;
  public state: ComponentState;

  constructor(private svAuth: AuthenticationService, public svImage: ImageService) {
    this.state = new ComponentState(true, 'Loading Account... Please wait.');
    this.user$ = new BehaviorSubject<IUser>(undefined);
  }

  ngOnInit() {
    this.svAuth.user$
      .pipe(
        take(1),
        catchError(error => {
          this.state.setError(error.message);
          return of(null);
        })
      )
      .subscribe(user => {
        this.user$.next(user);
        this.state.stopLoading('Successfully loaded account!', 0);
      });
  }

  getImageUrl(id) {
    return this.svImage.getUserImageUrl(id);
  }
}
