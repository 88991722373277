import { Component, OnInit, HostBinding } from '@angular/core';

import { fade } from '@orion-animations/fade.animation';

@Component({
  selector: 'app-orion-application-loading',
  templateUrl: './application-loading.component.html',
  animations: [fade],
  styleUrls: ['./application-loading.component.scss']
})
export class ApplicationLoadingComponent implements OnInit {
  @HostBinding('class.application-loading')
  public wrapperClass = true;

  @HostBinding('@fade')
  public fade = true;

  constructor() { }

  ngOnInit() {
  }
}
