import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, UrlSegment, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '@orion-modules/core/services/authentication.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad, CanActivate {
  constructor(
    private router: Router,
    private oidc: OidcSecurityService,
    private svAuth: AuthenticationService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.adminCheck();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.adminCheck();
  }

  private adminCheck() {
    return this.svAuth.state$.pipe(
      map(state => {
        if (!state.auth) {
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (!state.user) {
          return false;
        }
        return (state.user.isGlobalAdmin || state.user.isSiteAdmin);
      })
    ).toPromise();
  }
}
