import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AuthModule, OidcSecurityService, OidcConfigService, OpenIDImplicitFlowConfiguration } from 'angular-auth-oidc-client';

import { AuthenticationService } from './services/authentication.service';
import { environment } from '@orion-environments/environment';
import { LoggerService } from '@orion-modules/core/services/logger.service';


const oidc_configuration = `assets/oidc/auth-client.${environment.envName}.json`;
export function loadConfig(oidcConfigService: OidcConfigService) {
  return () => oidcConfigService.load(oidc_configuration);
}

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    AuthModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [OidcConfigService],
      multi: true,
    },
  ]
})
export class CoreModule {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private oidcConfigService: OidcConfigService,
    private svLogging: LoggerService) {

    this.oidcConfigService.onConfigurationLoaded.subscribe(() => {
      const oidcFlowConfig = new OpenIDImplicitFlowConfiguration();
      // merge configuration loaded from assets/auth.clientConfiguration.json
      Object.assign(oidcFlowConfig, this.oidcConfigService.clientConfiguration);

      // This is to fix the districts issue with logging in and being routed back to the login page.
      oidcFlowConfig.disable_iat_offset_validation = true;

      this.oidcSecurityService.setupModule(oidcFlowConfig, this.oidcConfigService.wellKnownEndpoints);
    });

  }
}
