export interface IFeedbackMessage {
    message: string;
    sentiment: number;
    subject: string;
    submitted: Date;
    reviewed: boolean;
    dateReviewed: Date;
}

export class FeedbackMessage implements IFeedbackMessage {
    public message: string;
    public subject: string;
    public sentiment: number;
    public reviewed: boolean;
    public dateReviewed: Date;
    public submitted: Date;

    public constructor(private msg: string) {
        this.message = this.msg;
        this.submitted = new Date();
    }
}
